import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import { Shell } from 'layout';
import { Pagination } from 'molecules';
import { BlogListing, MastheadBlogCategories } from 'organisms';

import '../scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    pageContext: PropTypes.shape({
        nextPagePath: PropTypes.string,
        numberOfPages: PropTypes.number,
        pageNumber: PropTypes.number,
        previousPagePath: PropTypes.string,
    }).isRequired,
};

function BlogIndexCategoryPage({
    data,
    location: {
        pathname,
    },
    pageContext,
}) {
    const doc = data?.prismicBlogHomePage?.data;
    const siteData = data?.prismicSite;
    const pageDescription = doc?.page_meta_description?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.text;
    let categories = [];
    doc?.category_links.forEach(({ category_link }) => {
        categories.push({
            icon: category_link?.document?.data?.category_icon,
            name: category_link?.document?.data?.category_name?.text,
        });
    });
    let blogListing = [];
    data?.allPrismicBlogArticlePage?.nodes.forEach(node => {
        blogListing.push({
            category: node?.data?.category?.document?.data?.category_name?.text,
            date: node?.data?.date,
            dateFormatted: node?.data?.date_formatted,
            description: {
                text: node?.data?.description?.text,
            },
            image: node?.data?.image,
            link: {
                link_type: 'Document',
                type: node?.type,
                uid: node?.uid,
            },
            title: {
                text: node?.data?.title?.text,
            },
        });
    });
    const pageUrl = `${process.env.GATSBY_SITE_URL}${pathname.substring(1)}`;
    const category = blogListing[0].category;
    const masthead = {
        description: doc?.description?.text,
        backgroundImage: doc?.background_image,
        strapline: doc?.strapline?.text,
        title: `Latest ${category.toLowerCase()} from Oxbury`,
        video: doc?.background_video,
    };

    return (
        <Shell
            pathname={pathname}
            seoDescription={pageDescription}
            seoTitle={pageTitle}
            seoImage={pageSocialImage}
            siteData={siteData}
        >
            <main className="l-grid__main" id="main-content">
                {(masthead?.description || masthead?.backgroundImage?.url || masthead?.strapline || masthead?.title || masthead?.video?.url) && (
                    <MastheadBlogCategories
                        categories={categories}
                        description={masthead.description}
                        pageUrl={pageUrl}
                        image={masthead.backgroundImage}
                        socialShare={true}
                        strapline={masthead.strapline}
                        title={masthead.title}
                        video={masthead.video}
                        viewAllButton={pathname.substring(1).split('/')[0]}
                    />
                )}
                <BlogListing
                    listing={blogListing}
                />
                {(pageContext.nextPagePath || pageContext.previousPagePath) && (
                    <Pagination
                        nextPageUrl={pageContext.nextPagePath}
                        prevPageUrl={pageContext.previousPagePath}
                    />
                )}
            </main>
        </Shell>
    );
}

export const query = graphql`
    query($skip: Int, $limit: Int, $slug: String) {
        prismicSite {
            ...FooterQuery
        }
        prismicSite {
            ...HeaderQuery
        }
        prismicSite {
            ...NavQuery
        }
        allPrismicBlogArticlePage(
            limit: $limit,
            skip: $skip,
            sort: {
                fields: data___date,
                order: DESC
            },
            filter: {
                data: {
                    category: {
                        slug: {
                            eq: $slug
                        }
                    }
                }
            }
        ) {
            nodes {
                data {
                    category {
                        document {
                            ... on PrismicCategory {
                                data {
                                    category_icon {
                                        alt
                                        dimensions {
                                            height
                                            width
                                        }
                                        url
                                    }
                                    category_name {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    date
                    date_formatted: date(formatString: "Do MMMM YYYY")
                    description {
                        text
                    }
                    image {
                        alt
                        dimensions {
                            height
                            width
                        }
                        url
                    }
                    title {
                        text
                    }
                }
                type
                uid
            }
        }
        prismicBlogHomePage {
            data {
                category_links {
                    category_link {
                        document {
                            ... on PrismicCategory {
                                data {
                                    category_icon {
                                        alt
                                        dimensions {
                                            height
                                            width
                                        }
                                        url
                                    }
                                    category_name {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
                background_image {
                    alt
                    dimensions {
                        height
                        width
                    }
                    url
                }
                background_video {
                    url
                }
                description {
                    text
                }
                strapline {
                    text
                }
                title {
                    text
                }
                page_meta_description {
                    text
                }
                page_meta_title {
                    text
                }
                page_social_share_image {
                    url
                }
            }
        }
    }
`;

BlogIndexCategoryPage.propTypes = propTypes;
BlogIndexCategoryPage.defaultProps = defaultProps;

export default withPreview(BlogIndexCategoryPage);
